import React, { Component } from "react"
import { Container } from "reactstrap"

import Layout from "../../components/layout"
import {
  Banner,
  PaddingWrapper,
  ReferencesFull,
  TitleH2Center,
} from "@igloonet-web/shared-ui"

import logoSZ from "../../images/reference/snowboard-zezula/logo-sz.png"
import logoplaneta from "../../images/reference/planetaher/logo-planeta.png"
import logodomydnes from "../../images/reference/domy-dnes/logo-domydnes.png"
import logoRC from "../../images/reference/royal-canin/logo-rc.png"
import logoDK from "../../images/reference/doktor-kladivo/logo-doktorkladivo.png"
import logoFenstar from "../../images/reference/fenstar/logo.svg"
import logomd from "../../images/reference/moravske-drevostavby/logo-moravskedrevostavby.png"
import logostorge from "../../images/reference/storge/logo-storge.png"
import logoldf from "../../images/reference/ldf/logoldf.svg"
import logoaktin from "../../images/reference/aktin/logo-aktin.png"

import logopanvicky from "../../images/reference/panvicky/logo-panvicky.png"

class ReferenceVyvoj extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const vyvojReferences = [
      {
        href: "/vyvoj/reference/royal-canin",
        image: logoRC,
        title: "royalcanin.cz",
        name: "Royal Canin",
      },
      {
        href: "/vyvoj/reference/doktor-kladivo",
        image: logoDK,
        title: "doktorkladivo.cz",
        name: "DoktorKladivo.cz",
      },
      {
        href: "/vyvoj/reference/fenstar",
        image: logoFenstar,
        title: "FenStar",
        name: "FenStar",
      },
      {
        href: "/vyvoj/reference/panvicky",
        image: logopanvicky,
        title: "panvicky.cz",
        name: "Panvicky.cz",
      },
      {
        href: "/vyvoj/reference/snowboard-zezula",
        image: logoSZ,
        title: "snowboard-zezula.cz",
        name: "SNOWBOARD ZEZULA",
      },
      {
        href: "/vyvoj/reference/planetaher",
        image: logoplaneta,
        title: "planetaher.cz",
        name: "Planetaher.cz",
      },
      {
        href: "/vyvoj/reference/domy-dnes",
        image: logodomydnes,
        title: "domy-dnes.cz",
        name: "Domy D.N.E.S.",
      },
      {
        href: "/vyvoj/reference/moravske-drevostavby",
        image: logomd,
        title: "moravske-drevostavby.cz",
        name: "MORAVSKÉ DŘEVOSTAVBY",
      },
      {
        href: "/vyvoj/reference/aktin",
        image: logoaktin,
        title: "aktin.cz",
        name: "Aktin",
      },
      {
        href: "/vyvoj/reference/storge",
        image: logostorge,
        title: "storge.cz",
        name: "Storge",
      },
      {
        href: "/vyvoj/reference/ldf-mendelu",
        image: logoldf,
        title: "ref",
        name: "LDF MENDELU",
      },
    ]

    return (
      <Layout>
        <Banner>
          <h1>Reference na vývoj</h1>
        </Banner>

        <Container>
          <PaddingWrapper>
            <TitleH2Center>Na čem v současné době pracujeme?</TitleH2Center>
            <p className={"text-center mb-5"}>
              Web či e-shop u nás není jednorázovým projektem, který za týden
              uděláte a pak si na něj pět let nevzpomenete. Našim zákazníkům
              pomáháme s většinou činností od prvních nápadů přes průzkumy a
              návrhy až k samotnému řešení. A tím práce na nejlepších projektech
              teprve začíná, stále je co vylepšovat.
            </p>

            <ReferencesFull anotherReferences={vyvojReferences} />
          </PaddingWrapper>
        </Container>
      </Layout>
    )
  }
}

export default ReferenceVyvoj
